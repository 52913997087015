
import React, { Component } from 'react';

import Header from './Header';
import Footer from './Footer';
import FormPopup from '../container/FormPopup';
import ReviewSec from '../container/ReviewSec';
import EuquireSec from '../container/EuquireSec';
import AwardSec from '../container/AwardSec';
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/src/owl.carousel.css';
import { AboutJson } from '../json/aboutJson';
import { PrivacyJson } from '../json/privacyjson';
import img1 from '../images/Executive page images/Lakhendra.webp';
import { CaseStudies } from '../json/CaseStudies';
import arrow2 from '../images/arrow2.webp';
import storyLogo from '../images/story-logo.webp';
import storyLogo1 from '../images/story-logo1.webp';
import storyLogo2 from '../images/story-logo2.webp';
import priImg from '../images/pri-img.webp';
import priImg1 from '../images/pri-img1.webp';
import priMob from '../images/pri-mob.webp';
import unsplash1 from '../images/unsplash1.webp'
import unsplash2 from '../images/unsplash2.webp'
import unsplash6 from '../images/unsplash6.webp'
import story from '../images/story-img.jpg'
import { HashLink as Link } from 'react-router-hash-link';
import priMob1 from '../images/pri-mob1.webp';
import { TailSpin } from 'react-loader-spinner'
import { AiOutlineMail } from "react-icons/ai";
import { Helmet } from "react-helmet";


class Privacy extends Component {

	constructor(props) {
		super(props)
		this.state = {
			ContentData: [],
			Storyjson: [],
			Helpjson: [],
			Officejson: [],
			teams: [],
			thePath: this.props.location.pathname,
			Culturejson: [],
			Loading: true,
		}
	}

	componentDidMount() {

		PrivacyJson.getPrivacy().then((data, err) => {
			console.log(data);
			if (!err) {
				this.setState({
					ContentData: data,
					Loading: false,


				});
			}
		});
		// AboutJson.getAbout().then((data, err) => {
			
		// 	if (!err) {
		// 		this.setState({
		// 			ContentData: data,
		// 			Loading: false,


		// 		});
		// 	}
		// });
		AboutJson.getStory().then((story, err) => {
			if (!err) {
				this.setState({
					Storyjson: story,
					Loading: false,


				});
			}
		});
		AboutJson.gethelp().then((help, err) => {
			if (!err) {
				this.setState({
					Helpjson: help,
					Loading: false,


				});
			}
		});
		AboutJson.getoffice().then((office, err) => {
			if (!err) {
				this.setState({
					Officejson: office,
					Loading: false,


				});
			}
		});
		AboutJson.getCulture().then((culture, err) => {
			if (!err) {
				this.setState({
					Culturejson: culture,
					Loading: false,


				});
			}
		});
		var a = this.state.thePath.substr(this.props.location.pathname.lastIndexOf('/') + 1);
		CaseStudies.iQuincesoftLeads(a).then((data, err) => {
			if (!err) {
				this.setState({
					teams: data
				});
			}
		});
	}


	render() {
		const { ContentData, teams, Storyjson, Helpjson, Officejson, Culturejson } = this.state;
		const officeSlide = {
			items: 1,
			responsive: {
				480: { items: 1 },
				320: { items: 1, }
			},
			loop: true,
			autoplay: true
		};

		const culture = {
			items: 4,
			responsive: {
				768: { items: 4 },
				480: { items: 1 },
				320: { items: 1, }
			},
			loop: true
		};

		return (
			this.state.Loading ? <div className="spinner"><TailSpin color="#00ccff" height={80} width={80} /></div> :
				<>
					<Header headerClass={'case-head'} />

					<Helmet>
						<title>Privacy Policy | Privacy Policy	</title>
						<meta name="description" content="India’s most valuable and customers oriented company established with the vision of managing long-lasting relationships and builds robust business solutions. Visit us!" />
					</Helmet>



					<div className="story-sec">
						<div className="container">
							<div className="row">
								
								<div className="col-lg-12 col-md-12">
									<ul >
										{ContentData && ContentData.map(StoryjsonS => {
											return (
												<>
													{StoryjsonS.content === '' ? null : <li key={StoryjsonS.id}><span>{StoryjsonS.name}</span> <p dangerouslySetInnerHTML={{ __html: StoryjsonS.content }}></p></li>}
												</>
											);
										})}
									</ul>
								</div>
							</div>
						</div>
					</div>

					
				

					

					

					

					

					

				

					

					<Footer />
				</>
		);
	}
}

export default Privacy;